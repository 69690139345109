const trackerToutes = {
    path: "/rastreamento",
    redirect: "/rastreamento/lotes",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/rastreamento/lotes",
        name: "tracker-lots-view",
        component: () => import("@/views/apps/tracker/lots/LotsView.vue"),
      },
      {
        path: "/rastreamento/lotes/cadastrar",
        name: "tracker-lots-create",
        component: () => import("@/views/apps/tracker/lots/LotsCreate.vue"),
      },  
      {
        path: "/rastreamento/:id/lotes",
        name: "tracker-lot-edit",
        component: () => import("@/views/apps/tracker/lots/LotsUpdate.vue"),
      },
      {
        path: "/rastreamento/fretes",
        name: "tracker-freights-view",
        component: () => import("@/views/apps/tracker/freights/FreightsView.vue"),
      },
      {
        path: "/rastreamento/fretes/cadastrar",
        name: "tracker-freights-create",
        component: () => import("@/views/apps/tracker/freights/FreightsCreate.vue"),
      },                            
      {
        path: "/rastreamento/:id/lotes",
        name: "tracker-freights-edit",
        component: () => import("@/views/apps/tracker/freights/FreightsUpdate.vue"),
      },
      {
        path: "/rastreamento/:id/fretes/visualizar",
        name: "tracker-freights-show",
        component: () => import("@/views/apps/tracker/freights/FreightsShow.vue"),
      },                         
    ],
  };

export default trackerToutes;