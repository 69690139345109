import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";


export interface Category {
  id: number;
  category: string;
  status: boolean;
}

export interface CooperatedUser {
  id: number;
  user: any;
  phone: string;
  type: string;
  type_client: string;
  selfie_image: string;
  cnh_image: string;
  created_at: string;
  status: string;
  name: string;
  codigo_cooperado: string;
}

@Module
export default class CooperatedModule extends VuexModule {
  pendingCooperatedUsers: Array<CooperatedUser> = [];

  get getPendingCooperatedUsers(): Array<CooperatedUser> {
    return this.pendingCooperatedUsers;
  }  

  @Mutation
  setPendingCooperatedUsers(cooperatedUsers) {
    this.pendingCooperatedUsers = cooperatedUsers.data;
  }

  @Action
  getAllPendingCooperatedUsers() {
  if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/cooperado/listPendingValidation")
        .then((response) => {
          console.log(response)
          this.context.commit('setPendingCooperatedUsers', response);
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: setCategories`)
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  ['changeStatusCooperated'](payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("/cooperado/changeStatus/" + payload.id, payload)
        .then((response) => {
          this.context.commit('setCurrentUser', response.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }    
  }
  @Action
  getCooperadoByCode(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/cooperado/getCooperadoByCode/"+id)
          .then((response) => {
            resolve(response.data);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  

  

 
}
