const peopleRoutes = {
    path: "/people",
    redirect: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/people",
        name: "people-list",
        component: () => import("@/views/apps/people/PeopleView.vue"),
      },
      {
        path: "/people/:id/profile/",
        name: "people-profile-edit",
        component: () => import("@/views/apps/people/PeopleProfileEdit.vue"),
      },
      
      {
        path: "/people/:id/notes/",
        name: "people-notes-edit",
        component: () => import("@/views/apps/people/PeopleProfileNotes.vue"),
      },
      
      {
        path: "/people/:id/reminders/",
        name: "people-reminders-edit",
        component: () => import("@/views/apps/people/PeopleProfileReminders.vue"),
      },
      
      {
        path: "/people/:id/reminders/:item/visualizar",
        name: "people-reminders-view",
        component: () => import("@/views/apps/people/PeopleProfileRemindersView.vue"),
      },      

      {
        path: "/people/:id/documents/",
        name: "people-documents-edit",
        component: () => import("@/views/apps/people/PeopleProfileDocuments.vue"),
      },
      
      {
        path: "/people/:id/cooperado/",
        name: "people-cooperado-edit",
        component: () => import("@/views/apps/people/PeopleProfileCooperado.vue"),
      },      
      
                            
    ],
  };

export default peopleRoutes;