import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";


export interface Category {
  id: number;
  category: string;
  status: boolean;
}

export interface Poster {
  id: number;
  poster: string;
  type: string;
  link: string;
  image: string;
  category: object;
  status: boolean;
}

@Module
export default class PublicityModule extends VuexModule {
  categories: Array<Category> = [];
  posters: Array<Poster> = [];

  get getCategories(): Array<Category> {
    return this.categories;
  }

  get getPosters(): Array<Poster> {
    return this.posters;
  }  


  @Mutation
  setCategories(categories) {
    this.categories = categories.data;
  }

  @Mutation
  setPosters(posters) {
    this.posters = posters.data;
  }  

  @Action
  getAllCategories() {
  if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/publicity/category")
        .then((response) => {
          console.log(response)
          this.context.commit('setCategories', response.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          console.log(`ERRO: setCategories`)
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  getAllPosters() {
  if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/publicity/poster")
        .then((response) => {
          this.context.commit('setPosters', response.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          console.log(`ERRO: setPosters`)
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  

  @Action
  getCategoryById(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/publicity/category/"+id)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  
  @Action
  getPosterById(id) {
  if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/publicity/poster/"+id)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  
  
  @Action
  createPublicityCategory(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("/publicity/category", payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: createPublicityCategory`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  createPublicityPoster(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderFormData();
        ApiService.post("/publicity/poster", payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: createPublicityPoster`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  
  
  @Action
  updatePublicityCategory(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.put("/publicity/category/" + payload.id, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: updatePublicityCategory`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  updatePublicityPoster(payload) {
    console.log(payload)
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderFormData();
        payload.formData.append('_method', 'PUT');
        ApiService.post("/publicity/poster/" + payload.id, payload.formData)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: updatePublicityPoster`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  

  @Action
  deletePublicityCategory(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete("/publicity/category/"+id)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: deletePublicityCategory`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  deletePublicityPoster(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete("/publicity/poster/"+id)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: deletePublicityPoster`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  

  

 
}
