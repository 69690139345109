const userRoutes = {
    path: "/usuarios",
    redirect: "/usuarios",
    component: () => import("@/layout/Layout.vue"),
    children: [
        {
            path: "/usuarios",
            name: "apps-users-view",
            component: () => import("@/views/apps/users/usersView.vue"),
        },
        {
            path: "/usuarios/cadastrar/",
            name: "users-create",
            component: () => import("@/views/apps/users/UserCreate.vue"),
        },
        {
            path: "/usuarios/:id/editar",
            name: "users-edit",
            component: () => import("@/views/apps/users/UserEdit.vue"),
        },                                    
    ],
  };

export default userRoutes;