import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";


export interface Rules {
  id: number;
  name: string;
  description: string;
  status: boolean;
}

@Module
export default class RuleModule extends VuexModule {
  rules: Array<Rules> = [];

  get getRules(): Array<Rules> {
    return this.rules;
  }


  @Mutation
  [Mutations.SET_RULES](rules) {
    this.rules = rules;
  }

  @Action
  [Actions.GET_RULES]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/rules")
        .then((response) => {
          console.log(response)
          this.context.commit(Mutations.SET_RULES, response.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          console.log(`ERRO: ${Actions.CREATE_USER}`)
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  
  @Action
  [Actions.CREATE_RULE](payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("/rules/store", payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: ${Actions.CREATE_USER}`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  

  @Action
  [Actions.DELETE_RULE](id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete("/rules/delete/"+id)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: ${Actions.DELETE_USER}`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }    

 
}
