const publicityRoutes = {
    path: "/propagandas",
    redirect: "/propagandas/categorias",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/propagandas/categorias",
        name: "publicities-categories-view",
        component: () => import("@/views/apps/publicities/categories/CategoriesView.vue"),
      },
      {
        path: "/propagandas/:id/categorias",
        name: "publicities-categories-edit",
        component: () => import("@/views/apps/publicities/categories/EditCategory.vue"),
      },
      {
        path: "/propagandas/posters",
        name: "publicities-posters-view",
        component: () => import("@/views/apps/publicities/posters/PostersView.vue"),
      },
      {
        path: "/propagandas/cadastrar/posters",
        name: "publicities-posters-create",
        component: () => import("@/views/apps/publicities/posters/PosterCreate.vue"),
      },
      {
        path: "/propagandas/:id/posters",
        name: "publicities-posters-edit",
        component: () => import("@/views/apps/publicities/posters/PosterEdit.vue"),
      },                        
    ],
  };

export default publicityRoutes;