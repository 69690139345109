import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

// 'name',
// 'origin',
// 'destiny',
// 'client_name',
// 'product',
export interface Freight {
  id: number;
  lot_id: number;
  user_id: number;
  user_name: string;
  lot: object;
  proof: string;
}

@Module
export default class FreightModule extends VuexModule {
  freights: Array<Freight> = [];

  get getFreights(): Array<Freight> {
    return this.freights;
  }

  @Mutation
  setFreights(freights) {
    this.freights = freights.data;
  }

  @Action
  getAllFreights() {
  if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/tracker/freight")
        .then((response) => {
          console.log(response)
          this.context.commit('setFreights', response.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          console.log(`ERRO: getAllFreights`)
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  getFreightById(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/tracker/freight/"+id)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  
  
  @Action
  createFreight(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("/tracker/freight", payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: createFreight`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  
  @Action
  updateFreight(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.put("/tracker/freight/" + payload.id, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: updateFreight`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  deleteFreight(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete("/tracker/freight/"+id)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: deleteFreight`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  deleteDoc(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete("/tracker/deleteDoc/"+id)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: deleteDoc`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  

  @Action
  uploadDocInternal(payload) {
    console.log(payload)
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderFormData();
        ApiService.post("/tracker/uploadDocInternal/", payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: uploadDoc`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }   

  

  

 
}
