const profileRoutes = {
    path: "/perfil",
    redirect: "/perfil",
    component: () => import("@/layout/Layout.vue"),
    children: [
        {
            path: "/perfil",
            name: "profile-edit",
            component: () => import("@/views/apps/profile/ProfileEdit.vue"),
        },                                   
    ],
  };

export default profileRoutes;