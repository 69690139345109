import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";


export interface Profile {
  id: number;
  name: string;
  type_client: string;
  status: string; 
}
 
@Module
export default class PeopleModule extends VuexModule {
  profiles: Array<Profile> = [];
  profile: any = {};
  notes: any = []
  reminders: any = []
  documents: any = []
  people_cooperados: any = []
  current_people_cooperado: any = {}

  get getProfiles(): Array<Profile> {
    return this.profiles;
  }

  get getPeopleCooperados(): any {
    return this.people_cooperados;
  }

  get getProfile(): any {
    return this.profile;
  }

  get getNotes(): any {
    return this.notes;
  }
  
  get getReminders(): any {
    return this.reminders;
  }

  get getDocuments(): any {
    return this.documents;
  }

  get getCurrentPeopleCooperado(): any {
    return this.current_people_cooperado
  }

  @Mutation
  setProfileCooperado(people_cooperados) {
    this.people_cooperados = people_cooperados.data
  }

  @Mutation
  setCurrentPeopleCooperado(cooperado) {
    this.current_people_cooperado = cooperado
  }

  @Mutation
  setProfiles(profiles) {
    this.profiles = profiles.data;
  }

  @Mutation
  setProfileReminders(reminders) {
    this.reminders = reminders.data;
  }
  
  @Mutation
  setProfilePeople(profile) {
    this.profile = profile.data
  }

  @Mutation
  setProfileNotes(notes) {
    this.notes = notes.data;
  }

  @Mutation
  setProfileDocuments(documents) {
    this.documents = documents.data;
  }  

  

  @Action
  getAllPeoples() {
  if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/people/profile")
        .then((response) => {
          console.log(response)
          this.context.commit('setProfiles', response.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          console.log(`ERRO: getAllPeoples`)
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  getPeopleProfileById(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/people/profile/"+id)
          .then((response) => {
            this.context.commit('setProfilePeople', response.data);
            resolve(response.data.data);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  
  @Action
  getNotesByProfile(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(`/people/profile/${id}/notes`)
          .then((response) => {
            this.context.commit('setProfileNotes', response.data);
            resolve(response.data.data);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  getRemindersByProfile(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(`/people/profile/${id}/reminders`)
          .then((response) => {
            this.context.commit('setProfileReminders', response.data);
            resolve(response.data.data);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  getCooperadoByProfile(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(`/people/profile/${id}/cooperado`)
          .then((response) => {
            this.context.commit('setProfileCooperado', response.data);
            resolve(response.data.data);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  updateReminderById(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.put(`/people/reminders/${payload.id}`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  

  @Action
  updateCooperado(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.put(`/people/cooperado/${payload.id}`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }    
  }

  @Action
  createCooperado(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(`/people/cooperado`, payload)
          .then((response) => {
            resolve(response);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }     
  }
  
  
  @Action
  getRemindersByUser(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(`/people/reminders/user/${payload.user}?from=${payload.from}&to=${payload.to}`)
          .then((response) => {
            // this.context.commit('setRemindersByUser', response.data);
            resolve(response);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }    
  }

  @Action
  getReminderById(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(`/people/reminders/${id}`)
          .then((response) => {
            // this.context.commit('setRemindersByUser', response.data);
            resolve(response.data);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }     
  }
  

  @Action
  getDocumentsByProfile(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(`/people/profile/${id}/documents`)
          .then((response) => {
            this.context.commit('setProfileDocuments', response.data);
            resolve(response.data.data);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  
  
  
  @Action
  updatePeopleProfile(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.put("/people/profile/" + payload.id, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: updatePeopleProfile`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  } 

  @Action
  deleteNote(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete(`/people/notes/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: deleteNote`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }    
  }

  @Action  
  deleteReminder(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete(`/people/reminders/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: deleteReminder`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }    
  }

  @Action  
  deleteDocument(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete(`/people/documents/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: deleteDocument`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }    
  }  

  
  

  @Action
  createNote(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("/people/notes", payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: createNote`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  
  @Action
  createReminder(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("/people/reminders", payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: createReminder`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  
  @Action
  createDocument(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("/people/documents", payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: createDocument`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  

  @Action
  deletePeople(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete("/publicity/category/"+id)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: deletePublicityCategory`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  updateCurrentPeopleCooperado(data) {
    this.context.commit('setCurrentPeopleCooperado', data);     
  }

  

 
}
