const ValidateAccountsRoute = {
    path: "/validacao",
    redirect: "/validacao/motoristas",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/validacao/motoristas",
        name: "validate-account-driver-view",
        component: () => import("@/views/apps/validate-account/drivers/DriverView.vue"),
      },
      {
        path: "/validacao/:id/motoristas",
        name: "validate-account-driver-show",
        component: () => import("@/views/apps/validate-account/drivers/DriverShow.vue"),
      },
      {
        path: "/validacao/cooperados",
        name: "validate-account-cooperated-view",
        component: () => import("@/views/apps/validate-account/cooperated/CooperatedView.vue"),
      },
      {
        path: "/validacao/:id/cooperados",
        name: "validate-account-cooperated-show",
        component: () => import("@/views/apps/validate-account/cooperated/CooperatedShow.vue"),
      },                                  
    ],
  };

export default ValidateAccountsRoute;