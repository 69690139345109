import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { number } from "yup";
import { User } from "./AuthModule";


export interface Users {
  id: number;
  name: string;
  email: string;
  status?: boolean;
  created_at: string;
}

export interface PendingUsers {
  id: number;
  user: any;
  phone: string;
  type: string;
  type_client: string;
  selfie_image: string;
  cnh_image: string;
  created_at: string;
  status: string;
  name: string;
  codigo_cooperado: string;
}

export interface UserServer {
  users: Users[];
}


@Module
export default class UserModule extends VuexModule implements UserServer {
  users: Array<Users> = [];
  pendingUsers = Array<PendingUsers>();
  currentClient = {} as User;
  collaborators: any = []

  get getUsers(): Array<Users> {
    return this.users;
  }

  get getPendingUsers(): Array<PendingUsers> {
    return this.pendingUsers;
  }

  get getCollaborators(): any {
    return this.collaborators;
  }

  get getUserById() {
    return (id: any) => {
      const data: any =  this.pendingUsers.find(user => user.user.id == id);
      if(data === undefined) {
        return this.currentClient;
      }
      return data;
    };
  }  

  @Mutation
  setCollaborators(collaborators) {
    this.collaborators = collaborators;
  }

  @Mutation
  [Mutations.SET_USERS](users) {
    this.users = users;
  }

  @Mutation
  [Mutations.SET_PENDING_USERS](pendingUsers) {
    this.pendingUsers = pendingUsers;
  }
  
  @Mutation
  ['setCurrentUser'](user) {
    this.currentClient = user;
  }   

  @Action
  [Actions.GET_USERS]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/users/lista")
        .then((response) => {
          console.log(response)
          this.context.commit(Mutations.SET_USERS, response.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_USER_BY_ID](id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/users/getUserById/" + id)
        .then((response) => {
          console.log(response)
          this.context.commit('setCurrentUser', response.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  

  @Action
  [Actions.GET_PENDING_USERS]() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/users/listPendingUsers")
        .then((response) => {
          this.context.commit(Mutations.SET_PENDING_USERS, response.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  
  @Action
  ['changeStatusClient'](payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("/users/changeStatusClient/" + payload.id, payload)
        .then((response) => {
          this.context.commit('setCurrentUser', response.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }    
  }

  
  @Action
  [Actions.CREATE_USER](payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("/users/store", payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: ${Actions.CREATE_USER}`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  
  @Action
  'createInternalUser'(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("/user/profile/createUser", payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: createInternalUser`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  

  @Action
  [Actions.DELETE_USER](id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete("/users/delete/"+id)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: ${Actions.DELETE_USER}`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }    

  @Action
  updateInternalProfileUser(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.put("/user/profile/updateInternalProfile/" + payload.id, payload.user)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: updateInternalProfile`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  updateClient(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.put("/user/profile/updateClient/" + payload.id, payload.user)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: updateClient`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }    
  }
  
  @Action
  updateProfile(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.put("/user/profile/updateProfile/" + payload.id, payload.user)
        .then((response) => {
          this.context.commit('setCurrentUser', response.data);
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: updateProfile`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  
  @Action
  getAvaiableCollaborators() {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/users/collaborators")
        .then((response) => {
          console.log(response)
          this.context.commit('setCollaborators', response.data.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  
 
}
