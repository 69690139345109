import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

// 'name',
// 'origin',
// 'destiny',
// 'client_name',
// 'product',
export interface Lot {
  id: number;
  name: string;
  origin: string;
  destiny: string;
  client_name: string;
  product: string;
}

@Module
export default class LotModule extends VuexModule {
  lots: Array<Lot> = [];

  get getLots(): Array<Lot> {
    return this.lots;
  }

  @Mutation
  setLots(lots) {
    this.lots = lots.data;
  }

  @Action
  getAllLots() {
  if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/tracker/lot")
        .then((response) => {
          console.log(response)
          this.context.commit('setLots', response.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          console.log(`ERRO: getAllLots`)
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  getLotById(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/tracker/lot/"+id)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch(({ response }) => {
            resolve(response.data);
          });
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  
  
  @Action
  createLot(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("/tracker/lot", payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: createLot`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  
  @Action
  updateLot(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.put("/tracker/lot/" + payload.id, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: updateLot`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  deleteLot(id) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete("/tracker/lot/"+id)
        .then((response) => {
          resolve(response)
        })
        .catch(({ response }) => {
          console.log(`ERRO: deleteLot`)
          resolve(response)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }  

  

 
}
