import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";


export interface Driver {
  id: number;
  user: any;
  phone: string;
  type: string;
  type_client: string;
  selfie_image: string;
  cnh_image: string;
  created_at: string;
  status: string;
  name: string;
}

@Module
export default class DriverModule extends VuexModule {
  avialableDrivers: Array<Driver> = [];

  get getAvailableDrivers(): Array<Driver> {
    return this.avialableDrivers;
  }

  @Mutation
  setAvailableDrivers(avialableDrivers) {
    this.avialableDrivers = avialableDrivers.data;
  }

  @Action
  getAllAvailableDrivers() {
  if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/drivers/getAvailableDrivers")
        .then((response) => {
          console.log(response)
          this.context.commit('setAvailableDrivers', response.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          console.log(`ERRO: getServerAvailableDrivers`)
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }


 
}
