import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import UserModule from "@/store/modules/UserModule";
import RuleModule from "@/store/modules/RuleModule";
import PublicityModule from "@/store/modules/PublicityModule";
import CooperatedModule from "@/store/modules/CooperatedModule";
import LotModule from "@/store/modules/LotModule";
import FreightModule from "@/store/modules/FreightModule";
import DriverModule from "@/store/modules/DriverModule";
import PeopleModule from "@/store/modules/PeopleModule";
import WhatsAppModule from "@/store/modules/WhatsAppModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    UserModule,
    RuleModule,
    PublicityModule,
    CooperatedModule,
    LotModule,
    FreightModule,
    DriverModule,
    PeopleModule,
    WhatsAppModule,
  },
});

export default store;
