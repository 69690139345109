import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

 
@Module
export default class WhatsAppModule extends VuexModule {
  bots: any = []

  get getBots(): any {
    return this.bots;
  }

  @Mutation
  setBots(bots) {
    this.bots = bots.data;
  }

  @Action
  getAllWhatsAppBots() {
  if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("/configs/whatsapp/bot")
        .then((response) => {
          console.log(response)
          this.context.commit('setBots', response.data);
          resolve(response.data)
        })
        .catch(({ response }) => {
          console.log(`ERRO: getAllWhatsAppBots`)
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  updateBotWhatsApp(payload) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.put(`/configs/whatsapp/bot/${payload.id}`, payload)
        .then((response) => {
          console.log(response)
          resolve(response.data)
        })
        .catch(({ response }) => {
          console.log(`ERRO: updateBotWhatsApp`)
          resolve(response.data)
        });
      });      
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }    
  }
 
}
